<script>
import { VForm } from 'vuetify/lib'

export default {
  name: 'cot-form',
  extends: VForm,
  provide () {
    return {
      forms: this.forms
    }
  },
  inject: {
    forms: { default () { return new Set() } }
  },
  beforeDestroy () {
    this.forms.delete(this)
  },
  mounted () {
    this.forms.add(this)
  },
  methods: {
    resetAll () {
      this.forms.forEach(form => form.reset())
    },
    resetAllValidation () {
      this.forms.forEach(form => form.resetValidation())
    },
    validateAll () {
      let allValid = true
      this.forms.forEach(form => {
        if (!form.validate()) {
          allValid = false
        }
      })
      return allValid
    }
  },
  watch: {
    inputs () {
      this.validateAll()
    }
  }
}
</script>
